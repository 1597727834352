<template>
  <v-row class="px-2 ml-md-0 pt-5">
    <h2 class="mb-0">{{ $t("signIn") }}</h2>
    <span>{{ $t("enterEmailPassword") }}</span>
    <v-col cols="12">
      <v-text-field
        :label="$t('emailAddress')"
        required
        color="#59D79D"
        @keydown.enter="onSubmit"
        :hide-details="emailError.length === 0"
        :error-count="emailError.length"
        :error-messages="emailError"
        v-model="email"
      ></v-text-field>
    </v-col>
    <v-col cols="12 relative">
      <v-text-field
        :label="$t('password')"
        required
        @keydown.enter="onSubmit"
        :type="showPassword ? 'text' : 'password'"
        :hide-details="errors.length === 0"
        color="#59D79D"
        :error-count="errors.length"
        :error-messages="errors"
        v-model="password"
      >
      </v-text-field>
      <v-icon v-show="showPassword" @click="onShowPassword" class="absolute">mdi-eye</v-icon>
      <v-icon v-show="!showPassword" @click="onShowPassword" class="absolute">mdi-eye-off</v-icon>
    </v-col>
    <v-col cols="12">
      <router-link
        :to="{ name: 'auth1.password-reset' }"
        class="float-end primary-prosk"
      >
        {{ $t("forgotPassword") }} ?
      </router-link>
    </v-col>
    <v-col offset="6" cols="6">
      <div class="float-end">
        <save-button :save="onSubmit" :loading="loading" :message="$t('signIn')"/>
      </div>
    </v-col>
    <v-col offset="3" cols="9">
      <v-btn
        small
        block
        color="#50b5ff"
        @click="loginGoogle"
        class="float-end white--text"
      >
        <v-icon left> mdi-google </v-icon>
        <span>{{ $t("loginGoogle") }}</span>
      </v-btn>
    </v-col>
    <v-col offset="3" cols="9">
      <v-btn block small color="#4385f5" @click="loginFacebook" class="float-end white--text">
        <v-icon left>
          mdi-facebook
        </v-icon>
        <span>{{ $t("loginFacebook") }}</span>
      </v-btn>
    </v-col>
    <v-col
      cols="12"
      md="7"
      :class="$vuetify.breakpoint.mobile ? 'text-center' : ''"
    >
      <span class="dark-color d-inline-block line-height-2"
        >{{ $t("dontHaveAccount") }} ?
        <router-link :to="{ name: 'auth1.sign-up1' }" class="primary-prosk">{{
          $t("signUp")
        }}</router-link>
      </span>
    </v-col>
    <v-col
      cols="12"
      md="5"
      class="mb-9"
      :class="$vuetify.breakpoint.mobile ? 'text-center' : ''"
    >
      <ul class="iq-social-media">
        <li>
          <a href="https://www.facebook.com/prosk.com.uy/" target="_blank">
            <v-icon color="#59D79D">mdi-facebook</v-icon>
          </a>
        </li>
        <li>
          <a href="https://www.linkedin.com/company/prosk/" target="_blank">
            <v-icon color="#59D79D">mdi-linkedin</v-icon>
          </a>
        </li>
        <li>
          <a href="https://instagram.com/prosk.uy?igshid=YmMyMTA2M2Y=" target="_blank">
            <v-icon color="#59D79D">mdi-instagram</v-icon>
          </a>
        </li>
      </ul>
    </v-col>
  </v-row>
</template>
<script>
import { mapActions } from 'vuex';
import axios from 'axios';
import moment from 'moment/moment';
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import SaveButton from '@/components/socialvue/buttons/SaveButton.vue';

export default {
  name: 'SignIn1',
  components: { SaveButton },
  mixins: [errorDialog],
  data () {
    return {
      errors: [],
      emailError: [],
      loading: false,
      email: '',
      query: {},
      password: '',
      showPassword: false,
      user: {}
    };
  },
  created () {
    if (this.$route.params.query) {
      this.query = JSON.parse(this.$route.params.query);
    }
  },
  methods: {
    ...mapActions({
      signIn: 'auth/signIn'
    }),
    createFreeSubscription () {
      const url = `${process.env.VUE_APP_BASE_URL}/api/orders`;
      const newDate = moment();
      const now = moment(moment.utc(newDate)).format('DD-MM-YYYY');
      const formData = {
        subscription_id: 1,
        user_id: this.query.userId,
        status: 20,
        price: 0,
        payment_date: now
      };
      axios
        .post(url, formData)
        .then(() => {
          this.$swal.fire({
            title: this.$t('awesome'),
            text: this.$t('successfulFreePlanSubscription'),
            confirmButtonColor: '#19AD93'
          });
          this.$router.push({ name: 'social.list' });
        })
        .catch((error) => {
          this.showError(error);
        });
    },
    onShowPassword () {
      this.showPassword = !this.showPassword;
    },
    loginGoogle () {
      window.location.href = process.env.VUE_APP_BASE_URL + '/login/google';
    },
    loginFacebook () {
      window.location.href = process.env.VUE_APP_BASE_URL + '/login/facebook';
    },
    validEmail: function (email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    resendVerifyEmail () {
      const url = `${process.env.VUE_APP_BASE_URL}/api/user/verification/resend`;
      const data = {
        email: this.email
      };
      axios
        .post(url, data)
        .then(() => {})
        .catch((error) => {
          this.loading = false;
          this.showError(error);
        });
    },
    accountNotVerified (error) {
      return error.response.data.errors && error.response.data.errors.login && error.response.data.errors.login.find(item => item === 'userHasNotBeenVerified');
    },
    onSubmit () {
      this.errors = [];
      this.emailError = [];
      const passwordValidation = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&\-_.;,\\])[A-Za-z\d@$!%*#?&\-_.;,\\]{8,}$/;
      if (this.email === '') {
        this.emailError.push(this.$t('emailRequired'));
      }
      if (!this.validEmail(this.email)) {
        this.emailError.push(this.$t('enterValidEmail'));
      }
      if (this.password === '') {
        this.errors.push(this.$t('passwordRequired'));
      }
      if (!this.password.match(passwordValidation)) {
        this.errors.push(this.$t('invalidPassword'));
      }
      if (
        this.password.match(passwordValidation) &&
        this.password !== '' &&
        this.email !== '' &&
        this.validEmail(this.email)
      ) {
        this.loading = true;
        const user = {
          email: this.email,
          password: this.password
        };
        this.signIn(user)
          .then(() => {
            this.loading = false;
            if (this.query.isFreeSubscription) {
              this.createFreeSubscription();
            } else {
              this.$router.push({ name: 'social.list' });
            }
          })
          .catch((error) => {
            this.loading = false;
            this.showError(error, true);
            if (this.accountNotVerified(error)) {
              this.resendVerifyEmail();
            }
          });
      }
    }
  }
};
</script>
<style scoped>
  .relative {
    position: relative;
    width: 100%;
  }

  .absolute {
    position: absolute;
    top: 32px;
    right: 12px;
  }

  @media(max-width: 768px) {
    .absolute {
      top: 24px;
      right: 12px;
    }
  }
</style>
